/* input#search {
    background-image: url('../../../style/img/no-img-found.png');
    background-repeat: no-repeat;
    text-indent: 20px;
} */

.vt_country_select_main_container {
    position: relative;
}

.vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 70px!important;
    color: #6e6e6e;
    padding: 0 10px !important;
    margin-top: 13px;
    height: 38px!important;
}
.abc{
    background-color: blue;
}

.vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected:focus {
    outline: 0;

}

/* dropdown */
.vt_country_select_main_container .vt_country_select_list_container {
    /* overflow-y: scroll!important; */
    overflow-x: hidden;
    z-index: 9;
    position: absolute;
    background: #fff;
    /* height: 130px; */
    /* border: 1px solid #ccc; */
    /* width: 98%; */
}

.vt_country_select_main_container .vt_country_select_list_container.with_scroll {
    overflow-y: scroll;
    height: 150px;
}

/* .vt_country_select_main_container .vt_country_select_list_container div {
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    font-family: Overpass,sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: .3px;
    color: #435b73;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95px;
    white-space: nowrap;
} */


.vt_country_select_main_container .vt_country_select_list_container .no_result_found {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
    width: 80%;
}

.vt_country_select_list_container img {
    margin-right: 5px;
}

.vt_country_select_list_container span {
    position: relative;
    /* top: -5px; */
    /* margin-left: 35px; */
}
.vt_country_select_list_container{
    overflow-x: hidden;
    z-index: 999;
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    width: 300px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 0 2px 1px #c4c5c5; */
    padding: 1px 1px;
  }
  .no_result_found {
    padding: 8px 10px;
}
.country_name_code{
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
}
 .country_name_code:hover
  {
    /* background-color: #f1f2f7; */
    background-color: #256380 !important;
    color: #fff !important;
      /* border-radius: 20px; */
  }

/* selected */
.vt_country_select_container input.vt-inspira-country-main {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 40px!important;
    color: #6e6e6e;
    padding: 0 10px 0 40px !important;;
    margin-top: 13px;
    height: 45px !important;
}
.vt_country_select_container{
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    /* padding: 10px 6px; */
    cursor: pointer;
    height: 45px;
}
.vt_country_select_container input{
    background-color: #fff;
    border: 0 !important;
    box-shadow: none;
    outline: none;
    height: 40px;
    background: #fff;
}
.vt_country_select_list_container{
    overflow-y: auto;
    max-height: 200px;
}
.vt_country_select_list_container::-webkit-scrollbar {
    width: 5px;
  }
  .vt_country_select_list_container::-webkit-scrollbar-track {
    background: #e7e7e7; 
  }
  .vt_country_select_list_container::-webkit-scrollbar-thumb {
    background: #888; 
  }
  .vt_country_select_list_container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.vt_country_select_container img.vt_flag_img {
    /* position: absolute; */
    /* top: -3px; */
    top: 0;
    left: 10px;
}
.vt_country_select_container input.vt-inspira-country-main:focus{
    outline: 0;
}







.add-creditcard-block.add-card-number .vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected{
    width: 126px !important;
    height: 38px!important;
}

.add-creditcard-block.add-card-number .vt_country_select_container input.vt-inspira-country-main {
    width: 96px!important;
    height: 38px!important;
}



.vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected {
    border-radius: 5px;
    border: 1px solid #e9e9ea;
    font-size: 14px;
    width: 89%!important;
    color: #6e6e6e;
    padding: 0 10px;
    margin-top: 0;
    height: 47px!important;
}

.vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_container input.vt-inspira-country-main {
    border-radius: 5px;
    border: 1px solid #e9e9ea;
    font-size: 14px;
    width: 71.5%!important;
    color: #6e6e6e;
    padding: 0 10px 0 40px;
    margin-top: 0;
    height: 47px!important;
}






.hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_container input.vt-inspira-country-main {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 50px!important;
    color: #6e6e6e;
    padding: 0 10px 0 40px !important;
    margin-top: 12px !important;
    height: 43px!important;
}

.hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_container img.vt_flag_img {
    margin: 0 !important;
}

.hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 80px!important;
    color: #6e6e6e;
    padding: 0 10px !important;
    margin-top: 12px !important;
    height: 43px!important;
}


@media screen and (max-width: 767px) and (min-width: 320px){
    .vt-profile-main-container-mobile .vt_country_select_container input.vt-inspira-country-main {
        border-radius: 3px;
        border: 1px solid #5d5b62;
        font-size: 15px;
        width: 100% !important;
        color: #6e6e6e;
        padding: 0 10px 0 40px !important;
        margin-top: 0;
        height: 50px!important;
        box-sizing: border-box;
    }

    .add-creditcard-block.add-card-number .vt_country_select_container input.vt-inspira-country-main {
        width: 100%!important;
        height: 50px!important;
    }

    .add-creditcard-block.add-card-number .vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected {
        width: 100% !important;
        height: 50px!important;
    }

    .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_container input.vt-inspira-country-main {

        width: 68%!important;
    }

    .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container input.vt-inspira-country-main.vt_country_selected {
        width: 85.5%!important;
    }

    .vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_container input.vt-inspira-country-main {
        width: 60.5%!important;

    }
}



/* width */
.add-country-code.add-country-phone ::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .add-country-code.add-country-phone ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .add-country-code.add-country-phone ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .add-country-code.add-country-phone ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .flight_cabin_class_arrow svg polygon{
    fill: var(--secondary_color);
  }

  .flag_style{
    height: 30px;
    width: 30px;
  }

  span.vt_country_select_main_container span.vt_country_select_container input {
    /* border: none !important; */
    /* border: 1px solid var(--secondary_color); */
    cursor: pointer;
    margin-top: 0;
}
span.vt_country_select_main_container_new span.vt_country_select_container input.input_country_style{
    border: none !important;
}
.vt_country_select_main_container .flight_cabin_class_arrow img{
    width: 13px !important;
    height: 13px !important;
    bottom: 17px !important;
    position: absolute;
    right: 10px;
    object-fit: contain;
    
  }
  span.vt_country_select_main_container span.vt_country_select_container img.flag_style {
    left: 10px;
    top: 10px;
}
span.vt_country_select_main_container span.vt_country_select_container img+input{
    /* padding-left: 0px; */
    cursor: pointer;
}

.signUp_country{
    width: 100%;
    height: 50px;
    margin: 20px 0 0;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color) !important;
    display: flex;
    align-items: center;
    gap: 5px;
    /* border: 1px solid #ccc; */
    padding: 0px 6px;
    cursor: pointer;
}
.country_input{
    width: 100%;
}
.country_input:focus{
    box-shadow: none;
    border: none;
}

@media screen and (max-width:767px) and (min-width:320px){
    .signUp_country{
        margin-top: 10px;
        gap: 5px;
    }
}